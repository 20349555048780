<template>
  <VCupertino
    v-if="isShowModal"
    id="city-select"
    :is-show="isShowModal"
    :drawer-options="{
      modal:true,
      cssClass:'cupertino-modal'
    }"

    @backdropTap="closeDialog"
    @didDismiss="closeDialog"
  >
    <div class=" dialog-city-select">
      <div class="dialog-city-select__body">
        <div class="dialog-city-select__title h2">Укажите свой город</div>
        <div class="dialog-city-select__delimiter" />

        <template v-if="!isCitiLoading">
          <div class="dialog-city-select__search">
            <input
              v-model="citySearch"
              class="h6"
              type="text"
              placeholder="Выберите город"
            />
            <div class="--popular h6">
              <span>Например:</span>
              <div
                v-for="(favorite) in favorites"
                :key="`favorite-${favorite.id}`"
                @click="() => setCity(favorite.id,favorite.name)"
              >
                {{ favorite.name }}
              </div>
            </div>
          </div>
          <div class="dialog-city-select__delimiter" />
          <div class="dialog-city-select__list scroll-customize">
            <template v-if="cities.length > 0">
              <div
                v-for="(city) in cities"
                :key="`city-${city.id}`"
                class="h5"
                @click="() => setCity(city.id,city.name)"
              >
                {{ city.name }}
              </div>
            </template>
            <template v-if="cities.length <= 0 && !!citySearch">
              <div class="dialog-city-select__message h5">
                Город не найден
              </div>
            </template>
          </div>
        </template>
        <template v-if="isCitiLoading">
          <div class="dialog-city-select__loading">
            Загружаем города...
          </div>
        </template>
      </div>
    </div>
  </VCupertino>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useUserCity } from '#imports';
import VCupertino from '~/components/widget/VCupertino.vue';

const {
  citiesList,
  isCitiLoading,
  isSelectCityPopupOpen,

  setCityUser,
  getAllCitiesList,
  changeSelectPopupOpen
} = useUserCity();

const citySearch = ref('');
const isShowModal = ref<boolean>(false);
const { $ym } = useNuxtApp();

const cities = computed(() => {
  const list = citiesList?.value?.cities || [];
  if (!citySearch.value) {
    return list;
  }

  const _citySearch = String(citySearch.value || '').toLowerCase();
  return list.filter((city: { name: string }) => Boolean((city?.name || '').toLowerCase().includes(_citySearch)));
});

const favorites = computed(() => citiesList?.value?.favorites);

onMounted(async () => {
  await getAllCitiesList();
  isShowModal.value = true;
});

const setCity = async (cityId: number, cityName?: string) => {
  await setCityUser(cityId, cityName);

  if (typeof $ym === 'function') {
    $ym('reachGoal', 'select_city');
  }
};
const closeDialog = () => {
  changeSelectPopupOpen(false);
};
</script>

<style lang="scss">
.dialog-city-select {
}

.dialog-city-select__body {
	width: 100%;
	padding: 40px;
	box-sizing: border-box;
}

.dialog-city-select__title {
}

.dialog-city-select__delimiter {
	margin: 30px 0;
	width: 100%;
	height: 1px;
	background-color: #F4F3F1;
}

.dialog-city-select__search {
	input {
		width: 100%;
		border-radius: 16px;
		border: 1px solid #EBEBEB;
		padding: 17px 24px;
		box-sizing: border-box;
		margin-bottom: 6px;

		&::placeholder {
			color: #989898;
		}
	}

	.--popular {
		display: flex;
		flex-wrap: wrap;
		margin-left: -16px;
		color: #989898;

		& > * {
			margin-left: 16px;
		}

		div {
			cursor: pointer;
			transition: all 0.2s;
			text-decoration: underline;

			&:hover {
				text-decoration-color: transparent;
			}
		}
	}
}

.dialog-city-select__list {
	display: flex;
	flex-wrap: wrap;
	margin-top: -16px;
	margin-left: -16px;
	max-height: calc(50vh);
	overflow: auto;

	& > * {
		width: calc(100% / 2 - 16px);
		margin-top: 16px;
		margin-left: 16px;
		cursor: pointer;
		transition: all 0.2s;
		text-decoration: underline;
		text-decoration-color: transparent;

		&:hover {
			text-decoration-color: black;
		}
	}
}

@media (max-width: 860px) {
	.dialog-city-select__delimiter {
		margin: 20px 0;
	}
	.dialog-city-select__search {
		& input {
			height: 48px;
		}
	}
	.dialog-city-select__body {
		padding: 20px;
	}
}
</style>
